/* Services Page Styling */
.services-container {
  padding: 2rem;
  background-color: #f3f4f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-title {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #1f2937;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 1.5rem;
  justify-content: center;
}

.extra-services {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.service-card {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.service-image {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #374151;
  margin-bottom: 0.75rem;
  text-align: center;
}

.service-list {
  list-style: none;
  padding: 0;
}

.service-list li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.service-icon {
  color: #10b981;
  margin-right: 0.5rem;
}
