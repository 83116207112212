.carousel-container {
    width: 100%;
    background-color: #2C4330; /* Matching footer background */
    padding: 20px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-track {
    display: flex;
    gap: 50px; /* Matches social-icons gap */
    white-space: nowrap;
    align-items: center;
  }
  
  .carousel-image {
    width: 80px; /* Adjusted size for better alignment */
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Mobile Responsive Adjustments */
  @media screen and (max-width: 768px) {
    .carousel-container {
      padding: 10px 0;
    }
  
    .carousel-track {
      gap: 20px; /* Reduce spacing for smaller screens */
    }
  
    .carousel-image {
      width: 60px;
      height: 60px;
    }
  }
  