/* Featured Ongoing Event */
.ongoing-event {
  position: relative;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  overflow: hidden;
  padding-top: 0;
}

.ongoing-event .featured-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.zen-dots-regular {
  font-family: "Zen Dots", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
}

.event-overlay {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adds a slight overlay for better readability */
  padding: 20px;
}
.megatitle{
  font-size: 80px;
}
.event-info {
  padding: 15px;
  border-radius: 8px;
  max-width: 1000px;
  font-family: "Zen Dots", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
}

.countdown {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
}
.date{
  font-size: 30px;
}
.event-buttons {
  display: flex;
  gap: 40px; /* Keep your spacing */
  justify-content: center; /* Centers the buttons */
  align-items: center; /* Ensures vertical alignment */
}
/* Rules Section */
.rules-section {
  background: rgba(255, 255, 255, 0.1); /* Light background for contrast */
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;
  text-align: left;
}

.rules-section h4 {
  font-size: 24px;
  font-weight: bold;
  color: #ffcc00; /* Highlighted color */
  margin-bottom: 10px;
}

.rules-section ul {
  list-style-type: none;
  padding: 0;
}

.rules-section li {
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.rules-section li:last-child {
  border-bottom: none;
}

/* Upcoming Events Section */
.upcoming-events {
  text-align: center;
  padding: 40px 20px;
}

.upcoming-events h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffcc00;
}

.events-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.event-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.event-card img {
  width: 100%;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
}

.event-card h3 {
  font-size: 20px;
  margin: 10px 0;
  color: #fff;
}

.event-card p {
  font-size: 16px;
  color: #ddd;
}

.event-card:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.2);
}
.event-details{
  color: black;
  background-color: white;
  margin: 50px;
  margin-bottom:0;
  padding-top:0;
  font-size: 20px;
}
.-image {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}
.full-width {
  width: 100%;
  max-width: 900px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  text-align: left;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.full-width h3 {
  font-size: 24px;
  color: #ffcc00;
  margin-bottom: 10px;
}

.full-width h4 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 8px;
}

.full-width p,
.full-width ul {
  font-size: 16px;
  color: #ddd;
}

.full-width ul {
  list-style: disc;
  padding-left: 20px;
}
/* Completed Events Page Styling */
.completed-events-container {
  padding: 0;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.completed-events-title {
  font-size: 100px;
  padding-bottom: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #1f2937;
}

/* Ensures each completed event takes full width */
.completed-events-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-items: center;
}

.completed-event-card {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Limits the max width for readability */
}

.completed-event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.completed-event-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.completed-event-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #374151;
  margin-bottom: 0.75rem;
  text-align: center;
}

.completed-event-date {
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 1rem;
  text-align: center;
}

.completed-event-description {
  font-size: 1rem;
  color: #4b5563;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 1rem;
}

/* Style for highlights section inside each completed event */
.completed-event-highlights {
  list-style: none;
  padding: 0;
  text-align: center;
}

.completed-event-highlights li {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}
