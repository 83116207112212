/* Container Styles */
.link-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  /* Card Styles */
  .link-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: #333;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background-color: #c5b799;
    font-family: "Rubik", serif;
  }
  
  .link-card:hover {
    transform: scale(1.05); 
  }
  
  /* Card Image */
  .card-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
  }
  
  /* Card Content */
  .card-content {
    padding: 15px;
    text-align: center;
  }
  
  .card-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .card-content p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .card-content h2 {
      font-size: 1.2rem;
    }
  
    .card-content p {
      font-size: 0.9rem;
    }
  }
  