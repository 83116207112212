/* General Styles */
.job-page-container {
    padding-bottom: 200px;
    padding: 20px;
    margin: 0 auto;
  }
  
  .page-title {
    text-align: center;
    font-size: 2.5rem;
    color: #0c2613;
    margin-bottom: 20px;
  }
  
  .filter-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .filter-options label {
    font-size: 1.1rem;
    color: #555;
  }
  
  .filter-options select {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .job1-listings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .job1-card {
    background-color: #fff;
    border:1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .job1-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .job1-title {
    font-size: 1.5rem;
    color: 0c2613;
    margin-bottom: 10px;
  }
  
  .company-name {
    font-size: 1.2rem;
    color: #0c2613;
    margin-bottom: 10px;
  }
  
  .location, .job1-type {
    font-size: 1rem;
    color: #0c2613;
    margin-bottom: 8px;
  }
  
  .job1-description {
    font-size: 0.95rem;
    color: #28422d;
    line-height: 1.5;
  }
  /* Responsive Design */
@media (max-width: 1024px) {
    .job1-listings {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .job1-listings {
      grid-template-columns: 1fr; /* 1 column for small screens */
    }
  }