/* Footer Container */
.footer-container {
  background-color: #0C2613;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

/* Footer Content */
.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 50px;
}

.column {
  display: flex;
  flex-direction: column;
}

.column h4 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.column a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 8px;
  transition: 0.3s;
}

.column a:hover {
  text-decoration: underline;
}

/* Call to Action */
.footer-call-to-action {
  text-align: right;
}

.footer-call-to-action p {
  font-size: 12px;
  margin-bottom: 5px;
}

.footer-call-to-action h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

/* Footer Bottom */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 20px;
  flex-wrap: wrap;
}

.policy-links {
  display: flex;
  gap: 20px;
}

.policy-links a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  transition: 0.3s;
}

.policy-links a:hover {
  text-decoration: underline;
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #fff;
  font-size: 18px;
  transition: 0.3s;
}

.social-icons a:hover {
  color: #ddd;
}
