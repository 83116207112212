/* General styling for the about section */
#about {
    background-color: #f8f8f8;
    padding: 40px 20px;
    text-align: center;
  }
  
  .playwrite-gb-s-about-h1 {
    font-size: 2.5rem;
    color: #27241e;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .about-content {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Styling for the achievements grid */
  .achievements-grid {
    display: grid;
    grid-template-columns: 1fr 1.5fr; /* Image takes 1 part, content takes 1.5 */
    gap: 20px;
    align-items: center;
  }
  
  /* Styling for image container */
  .achievements-image {
    text-align: center;
  }
  
  .achievements-img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    object-fit: cover; /* Ensures the image maintains its aspect ratio */
  }
  
  /* Styling for achievements content */
  .achievements-details {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    text-align: left;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border-left: 5px solid #4c6444;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .achievements-grid {
      grid-template-columns: 1fr; /* Stack image and content on smaller screens */
      text-align: center;
    }
  
    .achievements-img {
      width: 80%;
      height: auto;
      margin: auto;
    }
  }
  
  @media screen and (max-width: 480px) {
    .playwrite-gb-s-about-h1 {
      font-size: 2rem; /* Adjust for very small screens */
    }
  
    .achievements-img {
      width: 100%;
    }
  }
  