.company-container {
  background-color: #ffffff; /* Light background for better contrast */
  font-family: Arial, sans-serif;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners */
}

.company-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  background-color: #28422d; /* White background for the header */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 30px; /* Spacing below the header */
}

.company-title {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff; /* Darker text for better readability */
  margin-right: 20px;
}

.company-logo {
  max-width: 120px;
  height: auto;
  border-radius: 10px; /* Rounded corners for the logo */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.job-listings {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between job sections */
}

.job-section {
  background-color: #c4d6c8; /* White background for each job section */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.job-section:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.job-title {
  font-size: 34px;
  font-weight: bold;
  color: #0C2613; /* Blue color for titles */
  margin-bottom: 10px; /* Spacing below the title */
}

.job-details p {
  font-size: 20px;
  color: #555; /* Dark gray for better readability */
  margin: 5px 0;
  line-height: 2; /* Better line spacing */
}

.job-section h3 {
  font-size: 18px;
  margin-top: 15px;
  color: #222; /* Darker text for headings */
  font-weight: bold;
  font-size: 30px;
}

ul {
  padding-left: 20px;
  list-style-type: disc; /* Bullet points for lists */
  color: #555; /* Dark gray for list items */
  line-height: 2; /* Better line spacing */
  font-size: 20px;
}

.apply-section {
  text-align: center;
  margin-top: 30px;
}

.apply-button {
  background-color: #0c2613;
  color: white;
  font-size: 18px;
  padding: 12px 24px; /* Slightly larger padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s; /* Smooth hover effect */
}

.apply-button:hover {
  background-color: #28422D;
  transform: scale(1.05); /* Slight scale effect on hover */
}