/* About Container */
.about-container {
    padding: 20px;
    max-width: 95%;
    margin: 0 auto;
    text-align: left;
    color: #333;
    background-color: #fff7e6; /* Light background for contrast */
    margin-bottom: 20px;
    
  }
  
  /* Main Heading Style */
  .playwrite-gb-s-about-h1 {
    font-family: "Zen Dots", sans-serif;
  font-weight: 400;
  font-style: normal;
    color: #38301e;;
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Subheading Styles (Mission & Vision) */
  .mission-vision-heading {
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #38301e;;
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Paragraph Styles */
  .about-content p {
    font-family: "Rubik", serif;
    font-size: 20px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .playwrite-gb-s-hero-h1 {
      font-size: 2.5rem;
    }
  
    .mission-vision-heading {
      font-size: 1.5rem;
    }
  
    .about-content p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .playwrite-gb-s-hero-h1 {
      font-size: 2rem;
    }
  
    .mission-vision-heading {
      font-size: 1.3rem;
    }
  
    .about-content p {
      font-size: 0.9rem;
    }
  }
  