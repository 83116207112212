:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .job-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Large Screens (4 columns) */
@media screen and (min-width: 1024px) {
  .job-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Medium Screens (2 columns) */
@media screen and (max-width: 1023px) {
  .job-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small Screens (1 column) */
@media screen and (max-width: 768px) {
  .job-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
